import {useMutation, useQuery} from "@tanstack/react-query";
import { client } from "@/queries/client";

export function getUser({ enabled = true }: { enabled?: boolean } = {}) {
  return useQuery({
    queryKey: ["me"],
    enabled,
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/user/me", {
        signal,
      });
      if (data) return data;
      throw new Error(error);
    },
  });
}

export function switchSession() {
  return useMutation({
    mutationFn: async (
      airport: number
    ) => {
      const {data, error} = await client.get().POST("/api/session/new/{requested_airport}", {
        params: {
          path: {
            requested_airport: airport
          }
        }
      })
      if (data) return data;
      console.warn(error);
      throw new Error();
    }
  })
}

export function resetPassword() {
  return useMutation({
    mutationFn: async (
      {password, confirm}: {password: string, confirm: string}
    ) => {
      const {data, error} = await client.get().POST("/api/user/reset", {
        body: {
          confirm,
          password
        },
      })
      if (data) return data;
      console.warn(error);
      throw new Error();
    }
  })

}
