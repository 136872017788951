import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createTerminal, deleteTerminal,
  getTerminals,
  updateTerminal,
} from "@/queries/terminals";
import { components } from "@/lib/openapi";
import { SetupHeader, SetupItem } from "@/components/Setup";
import {
  Checkpoint,
  CheckpointEdit,
  createCheckpoint,
  getCheckpointsByTerminal,
  updateCheckpoint,
} from "@/queries/checkpoints";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "@/components/ui/form";
import { TextInput } from "@/components/form/FormControls";

type Terminal = components["schemas"]["TerminalSchema"];

const formSchema = z.object({
  terminal_name: z.string().min(1).max(5),
});

const TerminalForm: FunctionComponent = () => {
  const { record, setRecord, mode, setMode } = useCrudContext<Terminal>();
  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (terminal: Terminal) => {
    return {
      terminal_name: terminal?.terminal_name || "",
    };
  };
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createTerminal();
  const updateMutation = updateTerminal(record?.id || 0);
  const deleteMutation = deleteTerminal(record?.id || 0)

  function onDelete () {
    deleteMutation.mutate()
  }


  function onSubmit(values: z.infer<typeof formSchema>) {
    if (createMode) {
      createMutation.mutate({
        terminal_name: values.terminal_name,
      });
    } else {
      updateMutation.mutate({
        terminal_name: values.terminal_name,
      });
    }
  }

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Terminal"}
      editTitle={"Edit Terminal"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="terminal_name"
        render={({ field }) => (
          <TextInput field={field} label={"Terminal Name"} />
        )}
      />
    </CrudForm>
  );
};

const TerminalsPage: React.FC = () => {
  const { isLoading, data: terminals } = getTerminals();
  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Terminal>
      title={"Terminals"}
      addButton={"Add Terminal"}
      data={terminals}
      editForm={<TerminalForm />}
      makeDetails={(r) => {
        return { name: r.terminal_name };
      }}
      display={(r) => {
        return r.terminal_name;
      }}
    />
  );
};

export default TerminalsPage;
