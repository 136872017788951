import { atom, computed } from "nanostores";
import createClient from "openapi-fetch";
import { paths } from "@/lib/openapi";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINT } from "@/lib/constants";
import { getAuthToken } from "@/lib/auth";

export const authToken = atom<string | undefined>();
const token = getAuthToken();
if (token) {
  authToken.set(token);
}

export const client = computed(authToken, (currentToken) =>
  createClient<paths>({
    headers: currentToken ? { Authorization: `Bearer ${currentToken}` } : {},
    baseUrl: "/",
  }),
);
