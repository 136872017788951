// import { Button } from "@/components/ui/button"
// import {ThemeProvider} from "@/components/theme-provider";
//
//
// function App() {
//   return (
//     <>
//       <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
//         <div className={""}>
//           <Button>Foo</Button>
//           <div className={"text-green-400"}>Blue Text</div>
//         </div>
//       </ThemeProvider>
//     </>
//   )
// }
//
// export default App
//

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ThemeProvider } from "@/components/theme-provider";
import LoginPage from "@/pages/LoginPage";
import ProtectedRoute from "@/pages/ProtectedRoute";
import HomePage from "@/pages/HomePage";
import React, { useState } from "react";
import AppLayout from "@/pages/layouts/AppLayout";
import AdminLayout from "@/pages/layouts/AdminLayout";
import TerminalsPage from "@/pages/setup/TerminalsPage";
import CheckpointsPage from "@/pages/setup/CheckpointsPage";
import TicketingPage from "@/pages/setup/TicketingPage";
import GatesPage from "@/pages/setup/GatesPage";
import ManageDashboardPage from "@/pages/setup/ManageDashboardPage";
import ComponentsPage from "@/pages/setup/ComponentsPage";
import DashboardPage from "@/pages/report/Dashboard";
import { ExplorePage } from "@/pages/Explore";
import SessionDataProvider from "@/stores/SessionDataContext";
import { FlightsPage } from "@/pages/Flights";
import ManagePlaylistsPage from "@/pages/setup/PlaylistsPage";
import ConcoursesPage from "@/pages/setup/ConcoursesPage";
import ForecastPage from "@/pages/forecast/ForecastPage";
import ResetPage from "@/pages/ResetPage";

function App() {
  const [reactQueryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={reactQueryClient}>
      {/*<ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">*/}
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset" element={<ResetPage/>} />
          <Route
            element={
              <SessionDataProvider>
                <ProtectedRoute />
              </SessionDataProvider>
            }
          >
            <Route path="/" element={<AppLayout />}>
              <Route index element={<HomePage />} />
              <Route path="explore" element={<ExplorePage />} />
              <Route path="flights" element={<FlightsPage />} />
              <Route
                path={"/dashboard/:dashboardId"}
                element={<DashboardPage />}
              ></Route>
            </Route>
            <Route path="/forecast" element={<AppLayout />}>
              <Route index element={<ForecastPage/>} />
            </Route>
            <Route path="/setup" element={<AdminLayout />}>
              <Route path="terminals" element={<TerminalsPage />} />
              <Route path="dashboards" element={<ManageDashboardPage />} />
              <Route path="playlists" element={<ManagePlaylistsPage />} />
              <Route
                path="dashboards/:dashboardId/components"
                element={<ComponentsPage />}
              />
              <Route
                path="terminals/:terminalId/checkpoints"
                element={<CheckpointsPage />}
              />
              <Route
                path="terminals/:terminalId/concourses"
                element={<ConcoursesPage />}
              />
              <Route
                path="terminals/:terminalId/ticketing"
                element={<TicketingPage />}
              />
              <Route
                path="terminals/:terminalId/gates"
                element={<GatesPage />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
      {/*</ThemeProvider>*/}
    </QueryClientProvider>
  );
}

export default App;
