import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getAllReports, Report} from "@/queries/report";

interface SummaryChartProps {
  summaryKey: string;
  reports: Report[];
  title: string;
  yAxis: string;
  totals: boolean;
}

const SummaryChart: React.FC<SummaryChartProps> = ({ summaryKey, reports , title, yAxis, totals}) => {
  const uniqueNames = Array.from(new Set(reports.flatMap(report => report[summaryKey].breakdown.map(b => b.name))));

  const series = uniqueNames.map(name => ({
    name: "Terminal " + name,
    data: reports.map(report => {
      const breakdownItem = report[summaryKey].breakdown.find(b => b.name === name);
      return {
        y: breakdownItem ? breakdownItem.amount : 0,
        report_id: report.id
      };
    }),
  }));

  const totalSeriesData = reports.map(report => ({
    y: report[summaryKey].total,
    report_id: report.id
  }))
  let options = {}
  if(totals) {
    options = {
      chart: {
        type: "column",
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: ""
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: function () {
                if(this.report_id)
                  window.location.href = `/?report_id=${this.report_id}`;
              }
            }
          }

        },
      },
      xAxis: {
        categories: reports.map(report => report.target),
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: yAxis
        }
      },
      series: [{
        name: "Total",
        data: totalSeriesData
      }],
      legend: {
        enabled: false
      }
    };

  } else {
    options = {
      chart: {
        type: "column",
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: ""
      },
      plotOptions: {
        column: {
          // stacking: "stacked",
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              click: function () {
                if(this.report_id)
                  window.location.href = `/?report_id=${this.report_id}`;
              }
            }
          }

        },

      },
      xAxis: {
        categories: reports.map(report => report.target),
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: yAxis
        }
      },
      series
    };

  }

  return (
    <div className="bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-4 h-90 md:h-auto">
      <div
        className={
          "px-4 py-4 text-slate-300 border-b border-slate-700"
        }
      >
        {title}
      </div>
      <div className="w-full flex-grow highcharts-dark p-4">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </div>
  );
};


export const ForecastCharts = function() {
  const {data:reports, isLoading} = getAllReports()
  if(isLoading) {
    return null;
  }
  return <div>
    <SummaryChart summaryKey={"summary_pax"} reports={reports} title={"Passenger Traffic Forecast"} totals={true} yAxis={"Passengers"}/>
    <SummaryChart summaryKey={"summary_pax"} reports={reports} title={"Passenger Traffic Forecast by Terminal"} totals={false} yAxis={"Passengers"}/>

    <SummaryChart summaryKey={"summary_flights"} reports={reports} title={"Flight Count Forecast"} totals={true} yAxis={"Flights"}/>
    <SummaryChart summaryKey={"summary_flights"} reports={reports} title={"Flight Count Forecast by Terminal"} totals={false} yAxis={"Flights"}/>
  </div>
}
