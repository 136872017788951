// ComponentQueries.js
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Component = components["schemas"]["ComponentSchema"];

export function getComponents(dashboard: number) {
  return useQuery({
    queryKey: ["components", dashboard],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/components", {
        params: {
          query: {
            dashboard: dashboard,
          },
        },
        signal,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createComponent() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["ComponentCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/components", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["components"] });
    },
  });
}

export function updateComponent(component_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["ComponentUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/components/{component_id}", {
          params: {
            path: {
              component_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["components"] });
    },
  });
}

export function deleteComponent(component_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/components/{component_id}", {
          params: {
            path: {
              component_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["components"] });
    },
  });
}
