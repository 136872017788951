import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { getTerminals } from "@/queries/terminals";
import { getAllDashboards } from "@/queries/dashboard";
import { useSessionData } from "@/stores/SessionDataContext";
import {HeaderBar2} from "@/components/page/HeaderBar2";

const AdminLayout: React.FC = () => {
  // const { isLoading, data: terminals } = getTerminals();
  // const { isLoading: dashboardLoading, data: dashboards } = getAllDashboards();
  const { terminals, dashboards, airport_config ,is_staff} = useSessionData();

  // if (isLoading || dashboardLoading) {
  //   return null;
  // }

  const link = (name: string, url: string) => {
    return (
      <NavLink
        key={url}
        end
        className={({ isActive }) =>
          [isActive ? "navlink-active" : ""].join(" ") + " navlink"
        }
        to={url}
      >
        {name}
      </NavLink>
    );
  };

  return (
    <div>
      <HeaderBar2></HeaderBar2>
      <div className={"container h-vh"}>
        <div className="flex h-vh">
          <div className={"w-1/6 p-4 border-r border-slate-600 h-screen"}>
            {is_staff && link("Terminals", "/setup/terminals")}
            {is_staff && link("Dashboards", "/setup/dashboards")}
            {link("Playlists", "/setup/playlists")}
            {is_staff && <div className={"navsection"}>Dashboards</div>}
            {is_staff && dashboards.map((dashboard) =>
              link(
                dashboard.name,
                `/setup/dashboards/${dashboard.id}/components`,
              ),
            )}
            {is_staff && terminals.map((terminal) => {
              return (
                <div key={terminal.id}>
                  <div className={"navsection"}>
                    Terminal {terminal.terminal_name}
                  </div>
                  {link("Gates", `/setup/terminals/${terminal.id}/gates`)}
                  {link(
                    "Checkpoints",
                    `/setup/terminals/${terminal.id}/checkpoints`,
                  )}
                  {link(
                    "Ticketing",
                    `/setup/terminals/${terminal.id}/ticketing`,
                  )}
                  {airport_config.has_concourse && link("Concourses", `/setup/terminals/${terminal.id}/concourses`)}
                </div>
              );
            })}
          </div>
          <div className={"w-5/6"}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
