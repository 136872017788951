import React, {FunctionComponent, useEffect, useState} from "react";
import {buildReport, getReport} from "@/queries/report";
import {useSessionData} from "@/stores/SessionDataContext";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

import { Fragment } from 'react'
import { Disclosure, Menu, Transition, Popover} from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {switchSession} from "@/queries/user";
import {Button} from "@/components/ui/button";
import {useToast} from "@/components/ui/use-toast";
import {useReportContext} from "@/stores/ReportContext";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function ReportRefresh() {
  const [refreshing, setRefreshing] = useState(false);
  const { mutateAsync: runBuildReport } = buildReport();

  const { toast } = useToast()

  // Implementing the refresh click behaviour
  const onRefreshClick = async () => {
    setRefreshing(true);

    // Run the build report and start polling
    await runBuildReport();

    toast({
      title: "Refreshing Forecast",
      description: "Please wait 2-3 minutes to prepare a new forecast."
    })
  };

  return (
    <div className={"items-center hidden lg:flex"}>
      <Button
        variant="outline"
        type={"button"}
        onClick={onRefreshClick}
      >
        Refresh Forecast
      </Button>
    </div>
  );
}



export const HeaderBar2: FunctionComponent = () => {
  const {dashboards, code_iata, name, access, airport_id, is_staff} = useSessionData();
  const navigate = useNavigate();
  const {mutateAsync: getNewSession} = switchSession();
  const {forced, reportId} = useReportContext()
  let navigation = [
    { name: 'Overview', href: '/', current: location.pathname === '/' },
    { name: 'Dashboards', href: '/dashboard/' + dashboards[0]?.id || '', current: location.pathname.startsWith('/dashboard/') },
    { name: 'Explore Data', href: '/explore/', current: location.pathname.startsWith('/explore/') },
    { name: 'Flights', href: '/flights/', current: location.pathname.startsWith('/flights/') },
    { name: 'Forecast', href: '/forecast/', current: location.pathname.startsWith('/forecast/') },
    { name: 'Playlists', href: '/setup/playlists', current: location.pathname.startsWith('/setup/playlists') },
  ]

  if (is_staff) {
    navigation.push(
      { name: 'Manage Airport', href: '/setup/terminals', current: location.pathname.startsWith('/setup/') && !location.pathname.startsWith('/setup/playlists') },
    )
  }


  if(forced) {
    navigation = navigation.map(item => {
      const url = new URL(item.href, window.location.origin);
      url.searchParams.set('report_id', reportId.toString());
      return {
        ...item,
        href: url.pathname + url.search // Including pathname and search to preserve the base path and updated query params
      };
    });
  }

  const handleLogout = () => {
    const cookies = Cookies.get();
    for (let cookie in cookies) {
      Cookies.remove(cookie);
    }

    navigate("/login");
  };

  const switchAirport = async (id: number) => {
    console.log("Switching to airport ", id)
    const {success, token} = await getNewSession(id)
    if(success) {
      document.cookie = `auth_token=${token}; path=/`;

      // Redirect to "/" and force a full page reload
      window.location.href = "/";
      window.location.reload();
    }
  }

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 md:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={"/"} className={"flex items-center lg:ml-4"}><span className={"text-lg"}>RaidoHub</span> <span className={"bg-indigo-900 border border-indigo-600 text-center px-2 py-1 rounded text-xs ml-2 mr-2 opacity-80"}>BETA</span></Link>
                </div>
                <div className="hidden md:ml-6 md:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                <ReportRefresh />
                {access.length > 1 && <Menu as="div" className="relative ml-5">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 items-center">
                      Airport: {code_iata} <ChevronDownIcon className={"ml-2"}></ChevronDownIcon>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-0 focus:outline-none">
                      {access.map((item) => (<Menu.Item key={item.id}>
                          <a
                            onClick={() => switchAirport(item.id)}
                            className={'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200'}
                          >
                            {item.code_iata}
                          </a>
                      </Menu.Item>))}
                    </Menu.Items>
                  </Transition>
                </Menu>}
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-5">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 items-center">
                      {name} <ChevronDownIcon className={"ml-2"}></ChevronDownIcon>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleLogout}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
