export const zoneTypeOptions = [
  {
    value: "terminal",
    display: "Terminal",
  },
  {
    value: "concourse",
    display: "Concourse",
  },
  {
    value: "gate",
    display: "Gate",
  },
  {
    value: "checkpoint",
    display: "Checkpoint",
  },
  {
    value: "ticketing",
    display: "Ticketing",
  }
];

export const zoneRestrictOptions= [
  {
    value: "terminal",
    display: "Terminal",
  },
  {
    value: "concourse",
    display: "Concourse",
  },
];


export const zoneMeasureOptions = [
  {
    value: "pax",
    display: "Pax",
  },
  {
    value: "flight",
    display: "Flight",
  },
  {
    value: "bag",
    display: "Bags",
  },

];

export const zoneDirectionOptions = [
  {
    value: "arr",
    display: "Arrival",
  },
  {
    value: "dep",
    display: "Departure",
  },
];

export const zonePaxTypeOptions = [
  {
    value: "direct",
    display: "Direct",
  },
  {
    value: "connecting",
    display: "Connecting",
  },
];

export const zoneFlightTypeOptions = [
  {
    value: "domestic",
    display: "Domestic",
  },
  {
    value: "international",
    display: "International",
  },
];
