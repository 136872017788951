import { FunctionComponent } from "react";
import { cn } from "@/lib/utils";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import {useSessionData} from "@/stores/SessionDataContext";

export const SetupItem: FunctionComponent<{
  label: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ label, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cn([
        "flex items-center  border-b border-slate-600 py-2 px-4 ",
        isActive ? "bg-slate-400 text-slate-800" : "even:bg-slate-900",
      ])}
    >
      <div className="flex-grow">{label}</div>
      <div>
        <ChevronRightIcon></ChevronRightIcon>
      </div>
    </div>
  );
};

export const SetupHeader: FunctionComponent<{
  title: string;
  addButton: string;
  onAdd: () => void;
}> = ({ title, addButton, onAdd }) => {
  const {is_admin: isAdmin} = useSessionData()
  return (
    <div
      className={
        "mt-4 font-semibold flex items-center px-4 border-b border-slate-600 pb-4"
      }
    >
      <div className={"flex-grow text-xl"}>{title}</div>
      <Button variant={"secondary"} onClick={onAdd} disabled={!isAdmin}>
        {addButton}
      </Button>
    </div>
  );
};
