import { client } from "@/queries/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function getFlightData(report: number, direction: string) {
  return useQuery({
    queryKey: ["flights", report, direction],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/report/{report_id}/flights", {
          signal,
          params: {
            query: {
              direction,
            },
            path: {
              report_id: report,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function toggleFlightStar(report: number, direction: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (flight: number) => {
      const { data, error } = await client
        .get()
        .POST("/api/report/{report_id}/flight/{flight_id}/star", {
          params: {
            path: {
              report_id: report,
              flight_id: flight,
            },
          },
        });

      return {};
      // if (data) return data;
      // console.warn(error);
      // throw new Error();
    },

    onMutate: async (flightIndex: number) => {
      await queryClient.cancelQueries({ queryKey: ['flights', report, direction] })

      const previousFlights = queryClient.getQueryData(['flights', report, direction])

      // Create a deep copy of the previousFlights array in order to avoid any unexpected mutation
      // @ts-ignore
      const updatedFlights = previousFlights.map(flight => {
        if (flight.id === flightIndex) {
          return {
            ...flight,
            starred: !flight.starred,
          }
        }

        return flight
      })

      queryClient.setQueryData(['flights', report, direction], updatedFlights)

      // Return a context object with the snapshotted value
      return { previousFlights }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["flights", report, direction] });
    },
    onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['flights', report] })
    },

  });
}
