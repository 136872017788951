// DashboardQueries.js
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Dashboard = components["schemas"]["DashboardSchema"];

export function getAllDashboards() {
  return useQuery({
    queryKey: ["dashboards"],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/dashboards", { signal });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getDashboard(dashboardId) {
  return useQuery({
    queryKey: ["dashboards", dashboardId],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/dashboard/{dashboard_id}", {
          params: {
            path: {
              dashboard_id: dashboardId,
            },
          },
          signal,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createDashboard() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["DashboardCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/dashboards", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboards"] });
    },
  });
}

export function updateDashboard(dashboard_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["DashboardUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/dashboards/{dashboard_id}", {
          params: {
            path: {
              dashboard_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboards"] });
    },
  });
}

export function deleteDashboard(dashboard_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/dashboards/{dashboard_id}", {
          params: {
            path: {
              dashboard_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboards"] });
    },
  });
}
