import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Checkpoint = components["schemas"]["CheckpointSchema"];
export type CheckpointEdit = components["schemas"]["CheckpointUpdateSchema"];

export function getCheckpointsByTerminal(terminal: number) {
  return useQuery({
    queryKey: ["checkpoints", terminal],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/checkpoints", {
        signal,
        params: {
          query: {
            terminal: terminal,
          },
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createCheckpoint() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["CheckpointCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/checkpoints", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["checkpoints"] });
    },
  });
}

export function updateCheckpoint(checkpoint_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["CheckpointUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/checkpoints/{checkpoint_id}", {
          params: {
            path: {
              checkpoint_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["checkpoints"] });
    },
  });
}


export function deleteCheckpoint(checkpoint_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
    ) => {
      const { data, error } = await client
        .get()
        .DELETE("/api/checkpoints/{checkpoint_id}", {
          params: {
            path: {
              checkpoint_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["checkpoints"] });
    },
  });
}
