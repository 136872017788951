import React from "react";
import ForecastCalendar from "@/components/forecast/ForecastCalendar";
import {ForecastCharts} from "@/components/forecast/ForecastCharts";

const ForecastPage: React.FC = () => {
  return <div className={"md:px-6 lg:px-8 mt-4"}>
    <div className={"mt-2 text-2xl dark:text-indigo-400 border-b dark:border-b-slate-700 pb-2 mb-4"}>Forecasted Reports</div>
    <ForecastCalendar />
    <ForecastCharts />

  </div>
}

export default ForecastPage;
