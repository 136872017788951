import {components} from "@/lib/openapi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {client} from "@/queries/client";

export type Playlist = components["schemas"]["PlaylistSchema"];

export function getAllPlaylists() {
  return useQuery({
    queryKey: ["playlists"],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/playlists", { signal });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createPlaylist() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["PlaylistCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/playlists", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}

export function updatePlaylist(playlist_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["PlaylistUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/playlists/{playlist_id}", {
          params: {
            path: {
              playlist_id
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}

export function deletePlaylist(playlist_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/playlists/{playlist_id}", {
          params: {
            path: {
              playlist_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}
