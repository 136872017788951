// AppLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import {HeaderBar2} from "@/components/page/HeaderBar2";
import {Toaster} from "@/components/ui/toaster";
import {ReportForceBar} from "@/components/page/ReportForceBar";

interface AppLayoutProps {
  enableForceBar?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({ enableForceBar = true }) => (
  <div className={"h-screen w-full flex flex-col"}>
    <HeaderBar2 />
    {enableForceBar && <ReportForceBar />}
    <div className={"flex-grow"}>
      <Outlet />
    </div>
    <Toaster />
  </div>
);


export default AppLayout;
