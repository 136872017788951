// HomePage.tsx
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "@/queries/user";
import { Button } from "@/components/ui/button";
import { useSessionData } from "@/stores/SessionDataContext";
import {useReportContext} from "@/stores/ReportContext";
import {SummaryDisplay as Summary} from "@/pages/overview/Summary";
import {getReport} from "@/queries/report";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { dashboards } = useSessionData();
  const { reportId, report, formattedDate} = useReportContext()
  const {code_iata} = useSessionData()

  if(!report) {
    return <div>Initial Report not generated yet. Please contact support if you see this message for longer than 1 hour.</div>;
  }


  return <div className={"sm:mx-4 mx-4 mt-6"}>
    <div className={"text-center text-5xl text-indigo-400 mt-6"}>{code_iata} Overview for {formattedDate}</div>
    <div className="flex mt-8 md:mx-4 flex-col md:flex-row">
      {report.summary_flights && <div className="w-full md:w-1/4 p-4">
        <Summary summary={report.summary_flights} totalCaption={"Total Flights"} disableConcourse={false} />
      </div>}
      {report.summary_pax && <div className="w-full md:w-1/4  p-4">
        <Summary summary={report.summary_pax} totalCaption={"Total Passengers"} disableConcourse={false} />
      </div>}
      {report.summary_bags && <div className="w-full md:w-1/4  p-4">
        <Summary summary={report.summary_bags} totalCaption={"Total Bags"} disableConcourse={true}/>
      </div>}
      {report.summary_concerns && <div className="w-full md:w-1/4  p-4">
        <Summary summary={report.summary_concerns} totalCaption={"Total Pain Points"} disableConcourse={true} />
      </div>}
    </div>
    <div className={"prose prose-invert sm:mx-8 mx-4 mt-6"}>
      <h3>Legend:</h3>
      <ul>
        <li><span className={"text-green-600"}>Green</span> - Good Condition; Value is below 25% of Concern Threshold</li>
        <li><span className={"text-amber-600"}>Orange</span> - Watch Condition; Value is at-least 25% of Concern Threshold</li>
        <li><span className={"text-red-600"}>Red</span> - Warning Condition; Value has reached or exceeded Concern Threshold</li>
      </ul>
    </div>
  </div>
};

export default HomePage;
