import { components } from "@/lib/openapi";
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReportProvider } from "@/stores/ReportContext";

export type SessionDataType = components["schemas"]["UserSchema"];

declare global {
  interface Window {
    sessionData: SessionDataType;
  }
}

const SessionDataContext = createContext<SessionDataType | null>(null);

const SessionDataProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [sessionData, setSessionData] = useState<SessionDataType | null>(
    window.sessionData || null,
  );

  // Fetch the session data
  useEffect(() => {
    if (window.sessionData) {
      setSessionData(window.sessionData as SessionDataType);
    }
  }, []);

  return (
    <SessionDataContext.Provider value={sessionData}>
      <ReportProvider>{children}</ReportProvider>
    </SessionDataContext.Provider>
  );
};

// Custom hook to use the sessionData context
export const useSessionData = () => useContext(SessionDataContext);

export default SessionDataProvider;
