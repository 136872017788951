import React, {FunctionComponent, useEffect} from "react";
import {z} from "zod";
import {CrudForm, CrudPage, FormMode, useCrudContext,} from "@/components/page/CrudPage";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormField} from "@/components/ui/form";
import {SelectInput, TextareaInput, TextInput} from "@/components/form/FormControls";
import {createPlaylist, deletePlaylist, getAllPlaylists, Playlist, updatePlaylist} from "@/queries/playlists";
import {Simulate} from "react-dom/test-utils";
import {zoneTypeOptions} from "@/pages/setup/constants";
import {useSessionData} from "@/stores/SessionDataContext";

const formSchema = z.object({
  name: z.string().min(1).max(255),
  type: z.string().min(1),
  zone_type: z.string().optional(),
  threshold: z.string().min(1),
  message: z.string().min(5),
  concourse_id: z.string().optional(),
  terminal_id: z.string().optional(),
  enabled: z.boolean().optional(),
});

const PlaylistForm: FunctionComponent = () => {
  const { record, setRecord, mode, setMode } = useCrudContext<Playlist>();
  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (playlist?: Playlist) => {
    return {
      name: playlist?.name || "",
      type: playlist?.type || "above_threshold",
      zone_type: playlist?.zone_type || "gate",
      threshold: playlist?.threshold.toString() || '200',
      message: playlist?.message || "Limit exceeds set threshold",
      terminal_id: playlist?.terminal_id?.toString() || "",
      concourse_id: playlist?.concourse_id?.toString() || "",
      enabled: playlist?.enabled || true,
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createPlaylist();
  const updateMutation = updatePlaylist(record?.id || 0);
  const deleteMutation = deletePlaylist(record?.id || 0)

  const { terminals , concourses, airport_config: {has_concourse: hasConcourse}} = useSessionData();
  const terminalOptions = terminals.map((t) => ({
    value: t.id.toString(),
    display: t.terminal_name,
  }));

  const concourseOptions = concourses.map((t) => ({
    value: t.id.toString(),
    display: t.name,
  }));

  const playlistTypeOptions = [{
    value: "above_threshold",
    display: "Above Threshold"
  }]


  function onDelete () {
    deleteMutation.mutate()
  }


  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (createMode) {
      // @ts-ignore
      createMutation.mutate({
        name: values.name,
        type: values.type,
        zone_type: values.zone_type,
        threshold: parseInt(values.threshold),
        message: values.message,
        terminal_id: values.terminal_id ? parseInt(values.terminal_id) : null,
        concourse_id: values.concourse_id ? parseInt(values.concourse_id) : null,
        enabled: true
      });
    } else {
      updateMutation.mutate({
        name: values.name,
        type: values.type,
        zone_type: values.zone_type,
        threshold: parseInt(values.threshold),
        message: values.message,
        terminal_id: values.terminal_id ? parseInt(values.terminal_id) : null,
        concourse_id: values.concourse_id? parseInt(values.concourse_id) : null,
        enabled: true
      });
    }
  };

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Playlist"}
      editTitle={"Edit Playlist"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <TextInput field={field} label={"Playlist Name"} />
        )}
      />

      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <SelectInput
            field={field}
            label={"Playlist Type"}
            options={playlistTypeOptions}
          />
        )}
      />

            <FormField
        control={form.control}
        name="zone_type"
        render={({ field }) => (
          <SelectInput
            field={field}
            label={"Location Type"}
            options={zoneTypeOptions}
          />
        )}
      />


      <FormField
        control={form.control}
        name="terminal_id"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Terminal"}
            options={terminalOptions}
            placeholder={"All Terminals"}
          />
        )}
      />

      {hasConcourse && <FormField
        control={form.control}
        name="concourse_id"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Concourse"}
            options={concourseOptions}
            placeholder={"All Concourses"}
          />
        )}
      />}

      <FormField
        control={form.control}
        name="threshold"
        render={({ field }) => (
          <TextInput field={field} label={"Threshold"} />
        )}
      />

      <FormField
        control={form.control}
        name="message"
        render={({ field }) => (
          <TextareaInput field={field} label={"Message"} />
        )}
      />


    </CrudForm>
  );
};

const ManagePlaylistsPage: React.FC = () => {
  const { isLoading, data: playlists } = getAllPlaylists();
  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Playlist>
      title={"Playlists"}
      addButton={"Add Playlist"}
      data={playlists}
      editForm={<PlaylistForm />}
      makeDetails={(r) => {
        return { name: r.name };
      }}
      display={(r) => {
        return r.name;
      }}
    />
  );
};

export default ManagePlaylistsPage;
