import React, { FunctionComponent } from "react";
import Highcharts from "highcharts";
// import HighchartsMore from "highcharts/highcharts-more";
// import Stock from "highcharts/modules/stock";
// import NoDataToDisplay from "highcharts/modules/no-data-to-display";
// import SolidGauge from "highcharts/modules/solid-gauge";
import Heatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import { components } from "@/lib/openapi";
import { DataSchema } from "@/lib/types";
import {
  buildHeatmapData, buildMultiAxis,
  buildMultiSeriesLine,
  buildPercentageChart,
  buildSingleSeries,
  buildStacked,
  buildStackedPercent,
} from "@/viz/chart-data";
import {useMediaQuery} from "react-responsive";

if (typeof Highcharts === "object" && typeof window !== "undefined") {
  // HighchartsMore(Highcharts);
  // Stock(Highcharts);
  // NoDataToDisplay(Highcharts);
  // SolidGauge(Highcharts);
  Heatmap(Highcharts);
}

interface ChartProps {
  width: number;
  height: number;
  data: DataSchema;
}

const Unknown: FunctionComponent<ChartProps> = ({ width, height, data }) => {
  return <div>Unknown Chart</div>;
};

const TotalPassengersOld: FunctionComponent<ChartProps> = ({
                                                          width,
                                                          height,
                                                          data,
                                                        }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildSingleSeries({
          width,
          height,
          data,
          yAxisTitle: "Count",
          xAxisTitle: "Time"

        })}
      />
    </div>
  );
};

const TotalPassengers: FunctionComponent<ChartProps> = ({
                                                          width,
                                                          height,
                                                          data,
                                                        }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildMultiAxis({
          width,
          height,
          data,
          xAxisTitle: "Time",
          secondaryAxisSeriesIndex:1
        })}
      />
    </div>
  );
};


const ArrivingPassengers: FunctionComponent<ChartProps> = ({
                                                             width,
                                                             height,
                                                             data,
                                                           }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildPercentageChart({
          width,
          height,
          data,
          primary: "Arriving",
          secondary: "Departing",
          tooltipTitle: "Arriving"
        })}
      />
    </div>
  );
};

const DepartingPassengers: FunctionComponent<ChartProps> = ({
                                                              width,
                                                              height,
                                                              data,
                                                            }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildPercentageChart({
          width,
          height,
          data,

        })}
      />
    </div>
  );
};

const ConnectingPassengers: FunctionComponent<ChartProps> = ({
                                                               width,
                                                               height,
                                                               data,
                                                             }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildSingleSeries({
          width,
          height,
          data,
          yAxisTitle: "Connecting",
          xAxisTitle: "Time"
        })}
      />
    </div>
  );
};

const InternationalPassengers: FunctionComponent<ChartProps> = ({
                                                                  width,
                                                                  height,
                                                                  data,
                                                                }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildPercentageChart({
          width,
          height,
          data,
          primary: "Domestic",
          secondary: "International",
          tooltipTitle: "Domestic"
        })}
      />
    </div>
  );
};

const InternationalDeparting: FunctionComponent<ChartProps> = ({
                                                                 width,
                                                                 height,
                                                                 data,
                                                               }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildStacked({
          width,
          height,
          data,
          yAxisTitle: "Passengers",
          xAxisTitle: "Time"

        })}
      />
    </div>
  );
};

const InternationalArriving: FunctionComponent<ChartProps> = ({
                                                                width,
                                                                height,
                                                                data,
                                                              }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildStacked({
          width,
          height,
          data,
          yAxisTitle: "Passengers",
          xAxisTitle: "Time"

        })}
      />
    </div>
  );
};

const PassengersByTerminal: FunctionComponent<ChartProps> = ({
                                                               width,
                                                               height,
                                                               data,
                                                             }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildStacked({
          totalRequired: true,
          width,
          height,
          data,
          yAxisTitle: "Passengers",
          xAxisTitle: "Hour",
          secondaryAxisTitle: "Total"
        })}
      />
    </div>
  );
};

const BagsByTerminal: FunctionComponent<ChartProps> = ({
                                                               width,
                                                               height,
                                                               data,
                                                             }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildStacked({
          totalRequired: true,
          width,
          height,
          data,
          yAxisTitle: "Bags",
          xAxisTitle: "Hour",
          secondaryAxisTitle: "Total"
        })}
      />
    </div>
  );
};

const FlightsByTerminal: FunctionComponent<ChartProps> = ({
                                                               width,
                                                               height,
                                                               data,
                                                             }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildStacked({
          totalRequired: true,
          width,
          height,
          data,
          yAxisTitle: "Flights",
          xAxisTitle: "Hour",
          secondaryAxisTitle: "Total"
        })}
      />
    </div>
  );
};



const PassengersByGate: FunctionComponent<ChartProps> = ({
                                                           width,
                                                           height,
                                                           data,
                                                         }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildHeatmapData({
          width,
          height,
          data,
        })}
      />
    </div>
  );
};

const ActivityByHour: FunctionComponent<ChartProps> = ({
                                                         width,
                                                         height,
                                                         data,
                                                       }) => {
  return <div></div>;
};

const HourlyDistribution: FunctionComponent<ChartProps> = ({
                                                             width,
                                                             height,
                                                             data,
                                                           }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildMultiSeriesLine({
          width,
          height,
          data,
          yAxisTitle: "Passengers",
          xAxisTitle: "Time"
        })}
      />
    </div>
  );
};

const TrafficByLocation: FunctionComponent<ChartProps> = ({
                                                            width,
                                                            height,
                                                            data,
                                                          }) => {
  return <div></div>;
};

export const CHART_TYPES: Record<
  string,
  {
    title: string;
    component: FunctionComponent<ChartProps>;
  }
> = {
  total_passengers: {
    title: "Total Passengers",
    component: TotalPassengers,
  },
  arriving_passengers: {
    title: "Arriving Passengers",
    component: ArrivingPassengers,
  },
  departing_passengers: {
    title: "Departing Passengers",
    component: DepartingPassengers,
  },
  connecting_passengers: {
    title: "Connecting Passengers",
    component: ConnectingPassengers,
  },
  international_passengers: {
    title: "International Passengers",
    component: InternationalPassengers,
  },
  international_departing: {
    title: "International Departing Passengers",
    component: InternationalDeparting,
  },
  international_arriving: {
    title: "International Arriving Passengers",
    component: InternationalArriving,
  },
  passengers_by_terminal: {
    title: "Passengers by Terminal",
    component: PassengersByTerminal,
  },
  flights_by_terminal: {
    title: "Flights by Terminal",
    component: FlightsByTerminal,
  },
  bags_by_terminal: {
    title: "Bags by Terminal",
    component: BagsByTerminal,
  },
  passengers_by_gate: {
    title: "Passengers by Gate",
    component: PassengersByGate,
  },
  activity_by_hour: {
    title: "Passenger Activity by Hour",
    component: ActivityByHour,
  },
  hourly_distribution: {
    title: "Hourly Distribution of Passengers",
    component: HourlyDistribution,
  },
  traffic_by_location: {
    title: "Passenger Traffic at Different Locations",
    component: TrafficByLocation,
  },
};

export const buildChartComponent = (type: string) => {
  if (CHART_TYPES.hasOwnProperty(type)) {
    return CHART_TYPES[type]["component"];
  }
  return Unknown;
};
