import React, { useState } from 'react';
import {ExploreConfig} from "@/types/explore";
import {
  zoneDirectionOptions,
  zoneFlightTypeOptions,
  zoneMeasureOptions,
  zonePaxTypeOptions,
  zoneRestrictOptions,
  zoneTypeOptions
} from "@/pages/setup/constants";
import {clsx} from "clsx";
import {useSessionData} from "@/stores/SessionDataContext";
import {Button} from "@/components/ui/button";

type ConfigBuilderProps = {
  onConfigReady: React.Dispatch<React.SetStateAction<ExploreConfig>>;
};

type ConfigQuestionProps = {
  question: string;
  values: {value: string, display:string}[];
  selectedValue: null | string;
  setValue: (value: string|null) => void;
  complete?: boolean;
  nullOption?: string;
  disabledValues?: string[];
  disabledValueMessage?: string;
}

const NULL = "__null__"

const ConfigQuestion:React.FC<ConfigQuestionProps> = ({
                                                        question,
                                                        values,
                                                        selectedValue,
                                                        setValue,
                                                        complete,
                                                        nullOption,

                                                        disabledValues = [],
                                                        disabledValueMessage
                                                      }) => {
  const onValueClicked = (value: string) => {
    setValue(value);
  }
  let answer = null;
  if(selectedValue === NULL) {
    answer = nullOption
  } else if(selectedValue !== null) {
    answer = values.filter(v => v.value === selectedValue)[0].display;
  }
  return (
    <div className="pt-2 pb-2">
      <div className={"flex items-center text-sm"}>
        <div className={"w-72"}>{question}</div>
        {answer && <div className={"ml-4"}>{answer}</div>}
        {!answer && <div className={"flex ml-4 "}>
          {nullOption && (
            <div
              className={clsx(
                "px-3 min-w-32 text-center py-1 border-slate-700 border first:rounded-l-lg text-sm uppercase cursor-pointer",
                (selectedValue === NULL) && "dark:bg-slate-700 dark:text-slate-100 dark:border-b-indigo-600 border-b-4",
                (selectedValue !== NULL) && "dark:text-slate-200 dark:bg-slate-900"
              )}
              onClick={() => onValueClicked(NULL)}
              key={"nullOption"}
            >
              {nullOption}
            </div>
          )}
          {values.map(({display:d, value:v}) => {
            return <div
              className={
                clsx("px-3 py-1 min-w-32 text-center border-slate-700 border first:rounded-l-lg last:rounded-r-lg text-sm uppercase cursor-pointer",
                  v === selectedValue && "dark:bg-slate-700 dark:text-slate-100 dark:border-b-indigo-600 border-b-4",
                  (v !== selectedValue && !disabledValues.includes(v)) && "dark:text-slate-200 dark:bg-slate-900",
                  (disabledValues.includes(v)) && "dark:text-slate-600 dark:bg-slate-800",
                )
              }
              onClick={() => (!disabledValues.includes(v)) && onValueClicked(v)}
              key={v}
            >{d}</div>;
          })}
        </div>}
      </div>
      {disabledValueMessage && <div className={"ml-72 mt-2"}>
        <div className="ml-4 text-sm text-gray-600">
          {disabledValueMessage}
        </div>
      </div>}
    </div>
  )
}




const ConfigBuilder: React.FC<ConfigBuilderProps> = ({ onConfigReady  }) => {
  let [exploreConfig, setExploreConfig] = useState<ExploreConfig>({
    terminal_id: null,
    type: null,
    measure: null,
    direction: null,
    flight_type: null,
    pax_type: null,
    concourse_id: null,
    restrict_by: null
  })
  const [summary, setSummary] = useState<string | null>(null)
  const updateConfig = (key: keyof ExploreConfig, value: string | null) => {
    setExploreConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };


  const handleConfigValue = (key: keyof ExploreConfig, value: string | null) => {
    setExploreConfig((prevConfig) => {
      const newConfig = { ...prevConfig };
      newConfig[key] = value;
      return newConfig;
    });
  }


  const {terminals, concourses, airport_config: {has_concourse: hasConcourse}}  = useSessionData()
  const terminalOptions = terminals.map(t => ({
    display: t.terminal_name,
    value: t.id.toString()
  }))
  const concourseOptions = concourses.map(c => ({
    display: c.name,
    value: c.id.toString()
  }));

  concourseOptions.sort((a, b) => a.display.localeCompare(b.display));



  const generateSummary = (): string => {
    const {
      terminal_id,
      type,
      measure,
      direction,
      flight_type,
      pax_type,
      concourse_id,
      restrict_by
    } = exploreConfig;


    const summaryParts: string[] = [];

    if (measure && measure !== "__null__") {
      const measureDisplay = zoneMeasureOptions.find(opt => opt.value === measure)?.display;
      summaryParts.push(`Measuring: ${measureDisplay || measure}`);
    }

    if (type && type !== "__null__") {
      const typeDisplay = zoneTypeOptions.find(opt => opt.value === type)?.display;
      summaryParts.push(`Location Type: ${typeDisplay || type}`);
    }

    if (restrict_by && restrict_by !== "__null__") {
      const restrictDisplay = zoneRestrictOptions.find(opt => opt.value === restrict_by)?.display;
      summaryParts.push(`Restricted by: ${restrictDisplay || restrict_by}`);
    }

    if (terminal_id && terminal_id !== "__null__") {
      const terminal = terminalOptions.find(t => t.value === terminal_id);
      summaryParts.push(`Terminal: ${terminal?.display || terminal_id}`);
    }

    if (concourse_id && concourse_id !== "__null__") {
      const concourse = concourseOptions.find(c => c.value === concourse_id);
      summaryParts.push(`Concourse: ${concourse?.display || concourse_id}`);
    }

    if (direction && direction !== "__null__") {
      const directionDisplay = zoneDirectionOptions.find(opt => opt.value === direction)?.display;
      summaryParts.push(`Direction: ${directionDisplay || direction}`);
    }

    if (flight_type && flight_type !== "__null__") {
      const flightTypeDisplay = zoneFlightTypeOptions.find(opt => opt.value === flight_type)?.display;
      summaryParts.push(`Flight Type: ${flightTypeDisplay || flight_type}`);
    }

    if (pax_type && pax_type !== "__null__") {
      const paxTypeDisplay = zonePaxTypeOptions.find(opt => opt.value === pax_type)?.display;
      summaryParts.push(`Pax Type: ${paxTypeDisplay || pax_type}`);
    }

    return summaryParts.join(' | ');
  };


  const locationTypeNeeded = (hasConcourse && exploreConfig.measure === "flight") || (exploreConfig.measure == "pax")

  const locationTypes = exploreConfig.measure === "pax" ? zoneTypeOptions : [{
    value: "terminal",
    display: "Terminal",
  },
  {
    value: "concourse",
    display: "Concourse",
  }]
  const restrictNeeded = exploreConfig.type == "gate" && hasConcourse
  const whichConcourseNeeded = exploreConfig.restrict_by == "concourse"

  const locationTypeReady = exploreConfig.measure !== "pax" || exploreConfig.type !== null

  const paxFineTuneReady = (exploreConfig.measure !== "pax") ||
    (exploreConfig.measure === "pax" && exploreConfig.type !== "gate") ||
    (
    (exploreConfig.terminal_id !== null || exploreConfig.concourse_id !== null
    )
  )

  const terminalChoiceRequired = (exploreConfig.restrict_by == "terminal") || (exploreConfig.type == "gate" && !hasConcourse)
  const directionChoiceRequired = paxFineTuneReady && (exploreConfig.measure == "flight" || (exploreConfig.type !== "checkpoint" && exploreConfig.type !== "ticketing" && exploreConfig.type !== null))
  const paxTypeChoiceRequired = paxFineTuneReady && locationTypeReady && exploreConfig.measure !== "flight" && exploreConfig.measure !== null && exploreConfig.type !== "checkpoint" && exploreConfig.type !== "ticketing"
  const flightTypeChoiceRequired = paxFineTuneReady && locationTypeReady && exploreConfig.measure !== null

  let readyToSubmit = !(
    (exploreConfig.measure === null) ||
    (locationTypeNeeded && exploreConfig.type === null) ||
    (restrictNeeded && exploreConfig.restrict_by === null) ||
    (whichConcourseNeeded && exploreConfig.concourse_id === null) ||
    (terminalChoiceRequired && exploreConfig.terminal_id === null) ||
    (directionChoiceRequired && exploreConfig.direction === null) ||
    (flightTypeChoiceRequired && exploreConfig.flight_type === null) ||
    (paxTypeChoiceRequired && exploreConfig.pax_type === null)
  );

  const buildCleanConfig = () => {
    let cleanConfig = JSON.parse(JSON.stringify(exploreConfig, (key, value) => {
      return value === NULL ? null : value;
    }));

    if(cleanConfig.type === null && (exploreConfig.measure === "flight" || exploreConfig.measure === "bag")) {
      cleanConfig.type = "terminal"
    }


    return cleanConfig;
  }

  const saveButtonClicked = () => {
    // const cleanConfig = JSON.parse(JSON.stringify(exploreConfig, (key, value) => {
    //   return value === NULL ? null : value;
    // }));
    onConfigReady(buildCleanConfig());
    setSummary(generateSummary())
  }

  const onResetButtonClicked = () => {
    setSummary(null)
    const empty = {
      terminal_id: null,
      type: null,
      measure: null,
      direction: null,
      flight_type: null,
      pax_type: null,
      concourse_id: null,
      restrict_by: null
    }
    setExploreConfig(empty)
    onConfigReady(empty)
  }

  return (
    <div>
      {summary && <div>{summary} <a className={"dark:text-slate-600 underline ml-4 cursor-pointer text-sm"} onClick={onResetButtonClicked}>Start Over</a> </div>}
      {!summary && <div className={"flex flex-col divide-y divide-slate-800 border-t border-slate-800 border-b"}>
        <ConfigQuestion
          question={"What do you want to measure?"} values={zoneMeasureOptions}
          selectedValue={exploreConfig.measure} setValue={
          (val) => handleConfigValue( 'measure', val)
        } />
        {locationTypeNeeded && <ConfigQuestion
          question={"What Location Type?"} values={locationTypes.filter(option => {
          if (hasConcourse) {
            return true;
          }
          return option.value !== "concourse";
        })}
          selectedValue={exploreConfig.type} setValue={
          (val) => handleConfigValue('type', val)
        } />}
        {restrictNeeded && <ConfigQuestion
          question={"Should we restrict by terminal or concourse?"} values={zoneRestrictOptions}
          selectedValue={exploreConfig.restrict_by} setValue={
          (val) => handleConfigValue('restrict_by', val)
        } />}
        {terminalChoiceRequired && <ConfigQuestion
          question={"Which Terminal?"} values={terminalOptions}
          selectedValue={exploreConfig.terminal_id} setValue={
          (val) => handleConfigValue('terminal_id', val)
        } /> }
        {whichConcourseNeeded && <ConfigQuestion
          question={"Which Concourse?"} values={concourseOptions}
          selectedValue={exploreConfig.concourse_id} setValue={
          (val) => handleConfigValue('concourse_id', val)
        } />}
        {directionChoiceRequired && <ConfigQuestion
          question={"Which Direction?"} values={zoneDirectionOptions}
          nullOption={"Both"}
          selectedValue={exploreConfig.direction} setValue={
          (val) => handleConfigValue('direction', val)
        } /> }
        {flightTypeChoiceRequired && <ConfigQuestion
          question={"Which Flight Type?"} values={zoneFlightTypeOptions}
          nullOption={"All"}
          selectedValue={exploreConfig.flight_type} setValue={
          (val) => handleConfigValue('flight_type', val)
        } /> }
        {paxTypeChoiceRequired && <ConfigQuestion
          question={"Which Pax Type?"} values={zonePaxTypeOptions}
          nullOption={"All"}
          selectedValue={exploreConfig.pax_type} setValue={
          (val) => handleConfigValue('pax_type', val)
        } /> }
      </div>}
      {!summary && <div className="flex mt-4 pt-4 space-x-4">
        {readyToSubmit && <Button variant={"default"} onClick={saveButtonClicked}>See Data</Button>}
        <div className="flex-grow"></div>
        <Button variant={"secondary"} onClick={onResetButtonClicked}>Start Over</Button>
      </div>}


    </div>

  );
};

export default ConfigBuilder;
