import React, {createContext, useContext, useState, useEffect} from "react";
import {getLatestReport, getReport, Report} from "@/queries/report";
import {useLocation} from "react-router-dom";


// The context
type ReportContextType = {
  reportId: number;
  report: Report;
  forced: boolean;
  formattedDate: string;
};

// Create the context
const ReportContext = createContext<ReportContextType | undefined>(undefined);

// Function to use the context
export function useReportContext() {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }
  return context;
}

// Define the provider
export function ReportProvider({children}: React.PropsWithChildren<{}>) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const forceReportId = searchParams.get('report_id');

  const {data: report, isLoading} = forceReportId ?
    getReport({report: forceReportId}) : getLatestReport();




  if (isLoading) {
    return <div>Loading...</div>;
  }

  const date = new Date(report.target ? report.target + " 00:00:00" : report.created_at.replace('Z', ""));
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return (
    <ReportContext.Provider value={{reportId: report.id, report, forced: !!forceReportId, formattedDate}}>
      {children}
    </ReportContext.Provider>
  );
}
