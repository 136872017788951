// LoginPage.tsx
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  CardFooter,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  CollapsibleTrigger,
  CollapsibleContent,
  Collapsible,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { authToken, client } from "@/queries/client";
import myaxios from "@/lib/axios";
import { useQueryClient } from "@tanstack/react-query";

interface LocationState {
  from: {
    pathname: string;
  };
}

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const queryClient = useQueryClient();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(undefined);

    try {
      const response = await myaxios.post("/auth/login", {
        username,
        password,
      });

      if (response.data.success && response.data.token) {
        document.cookie = `auth_token=${response.data.token}; path=/`;

        // Redirect to "/" and force a full page reload
        window.location.href = "/";
        window.location.reload();
      } else if (response.data.message) {
        setErrorMessage(response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
        // TODOSENTRY
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again later.");
      // TODOSENTRY
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Please enter your username and password to login.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {errorMessage && (
            <div className="opacity-70 dark:bg-orange-900 border dark:border-orange-600 rounded px-4 py-2 mb-4 text-sm">
              {errorMessage}
            </div>
          )}
          <div className="space-y-2">
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              placeholder=""
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/*<Collapsible>*/}
          {/*  <CollapsibleTrigger className="font-semibold flex items-center gap-1">*/}
          {/*    Advanced Options*/}
          {/*    <ChevronDownIcon className="h-4 w-4 transition-all transform [&[data-state=open]>svg]:rotate-180" />*/}
          {/*  </CollapsibleTrigger>*/}
          {/*  <CollapsibleContent>*/}
          {/*    <div className="space-y-2">*/}
          {/*      <Label htmlFor="airport">Airport (IATA Code)</Label>*/}
          {/*      <Input id="airport" placeholder="Enter IATA Code" />*/}
          {/*    </div>*/}
          {/*  </CollapsibleContent>*/}
          {/*</Collapsible>*/}
        </CardContent>
        <CardFooter>
          <Button className="w-full" onClick={handleLogin}>
            Sign in
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

function ChevronDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  );
}

export default LoginPage;
