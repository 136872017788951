import { Gate } from "@/queries/gate";
import React, { FunctionComponent } from "react";
import Select, { GroupBase } from "react-select";

interface SelectOption {
  id: number;
  display: string;
}
interface MultiSelectProps {
  options: SelectOption[];
  onChange: (number) => void;
  value: number[];
}

export const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  options,
  onChange,
  value,
}) => {
  const handleChange = (val, foo) => {
    onChange(val.map((v) => parseInt(v.value)));
  };
  const mappedOptions = options.map((val) => {
    return {
      value: val.id.toString(),
      label: val.display,
    };
  });

  //   const mappedOptions: GroupBase<{value: string, label: string}>[] = [{
  //     options: options.map((val) => {
  //         return {
  //             'value': val.id.toString(),
  //             'label': val.display
  //         }
  //     })
  // }]

  const mappedValue = value.map((val) => {
    const option = options.find((opt) => opt.id === val);
    if (option) {
      return {
        value: option.id.toString(),
        label: option.display,
      };
    }
    return null; // or else any default value
  });

  return (
    <Select
      className="my-react-select-container"
      classNamePrefix="my-react-select"
      value={mappedValue}
      onChange={handleChange}
      options={mappedOptions}
      isMulti={true}
    />
  );
};
