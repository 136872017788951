import React, {FunctionComponent, useMemo, useState} from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
// import Stock from "highcharts/modules/stock";
// import NoDataToDisplay from "highcharts/modules/no-data-to-display";
// import SolidGauge from "highcharts/modules/solid-gauge";
import Heatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Concern, useExplore } from "@/components/explore/ExploreContext";

if (typeof Highcharts === "object" && typeof window !== "undefined") {
  HighchartsMore(Highcharts);
  // Stock(Highcharts);
  // NoDataToDisplay(Highcharts);
  // SolidGauge(Highcharts);
  Heatmap(Highcharts);
}

export function findClosestTimeIndex(times: string[]): number {
  // Function to convert a time string to minutes since midnight
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Get current time in minutes since midnight
  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();

  // Map each time string to its difference from the current time
  const differences = times.map((time) =>
    Math.abs(timeToMinutes(time) - currentMinutes),
  );

  // Find the index of the smallest difference
  return differences.indexOf(Math.min(...differences));
}

export const HeatMapView: FunctionComponent = ({measure}: {measure: string}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [zoneBreakdown, setZoneBreakdown] = useState<null | string>(null);
  const [alert, setAlert] = useState<null | Concern>(null);

  const { zones, concerns, times } = useExplore();
  const closestIndex = findClosestTimeIndex(times);

  const legendText = {
    pax: "Passengers",
    flight: "Flights",
    bags: "Bags"
  }[measure]

  const suffixText = {
    pax: "Passengers",
    flight: "Flights",
    bags: "Bags"
  }[measure] ?? "Passengers"

  const conLookup = useMemo(() => {
    const lookup = {};
    if (concerns && concerns.length > 0) {
      concerns.forEach((c, index) => {
        lookup[`${c.zone_index}-${c.time_index}`] = index;
      });
    }

    return lookup;
  }, [concerns]);


  const data = useMemo(() => {
    const result = [];
    zones.forEach((zone, yIndex) => {
        zone.values.forEach((value, xIndex) => {
            let conKey = `${yIndex}-${xIndex}`;
            if (conLookup.hasOwnProperty(conKey)) {
                result.push({
                    x: xIndex,
                    y: yIndex,
                    value: value,
                    conIndex: conLookup[conKey],
                    color: "#8b0000",
                });
            } else {
                result.push([xIndex, yIndex, value]);
            }
        });
    });

    return result;
}, [zones, conLookup]);

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },

    chart: {
      styledMode: true,
      height: 1000,
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: true,
      title: legendText ?? "Passengers"
    },
    tooltip: {
      formatter: function (foo) {
        const x = this.point.x;
        const y = this.point.y;
        const zone = zones[y];

        return `${zone.name} @ ${times[x]} - ${zone.values[x]} ${suffixText}`;
      },
    },
    series: [
      {
        type: "heatmap",
        name: "Traffic",
        borderWidth: 1,
        data: data,
        dataLabels: {
          enabled: false,
        },
        turboThreshold: 50000,
        events: {
          click: function (event) {
            const x = event.point.x;
            const y = event.point.y;
            const zone = zones[y];

            if (!zone.notes[x]) {
              return;
            }

            setTitle(`Breakdown: ${zone.name} @ ${times[x]}`);
            setZoneBreakdown(zone.notes[x]);

            if (event.point.color === "#8b0000") {
              // @ts-ignore
              setAlert(concerns[event.point.conIndex]);
            } else {
              setAlert(null);
            }

            setOpen(true);
          },
        },
      },
    ],
    xAxis: {
      categories: times,
      plotBands: [
        {
          from: closestIndex + 0.5,
          to: closestIndex + 1.5,
          zIndex: 10,
        },
      ],
    },
    yAxis: {
      categories: zones.map((zone) => zone.name),
      title: null,
    },
    colorAxis: {
      min: 0,
      minColor: "#1A237E", // Dark Blue
      maxColor: "#FF8C00", // Medium-Dark Orange
    },
  };

  return (
    <div className={"w-full highcharts-dark"}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={options}
      />
      <div className={"text-center text-sm text-slate-300 mb-4 flex items-center justify-center"}><div className={"mr-2 w-3 h-6 bg-red-800"} /> Pax Exceeds Set Threshold</div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <div className={" prose mt-2"}>
            <pre>{zoneBreakdown}</pre>
          </div>
          {alert && (
            <div className={"prose mt-4 mb-4 dark:prose-invert prose-sm"}>
              <h3>Passenger Count Exceeds Threshold</h3>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"
                    >
                      Threshold
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"
                    >
                      Observed
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-600">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">
                      {alert.threshold}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-red-600">
                      {alert.value}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
